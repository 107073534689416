import { useState } from "react"

import { Button } from "@fourel/ui"

import { WhatsAppIntegration } from "#pages/login/whatsapp-integration/whatsapp-integration.js"

export const WhatsappModal = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="w-full bg-violet-900 p-0 hover:bg-violet-500"
      >
        Connect
      </Button>
      <WhatsAppIntegration open={open} setOpen={() => setOpen(!open)} />
    </>
  )
}
