import { Config } from "#config-fe.js"

export const Feature = {
  AdvertiseRoute: "AdvertiseRoute",
  CancelledOffersRoute: "CancelledOffersRoute",
  DashboardRoute: "DashboardRoute",
  PaymentsRoute: "PaymentsRoute",
  Integrations: "Integrations",
  SettingsBillingRoute: "SettingsBillingRoute",
  SettingsAccount: "SettingsAccount",
  ClientFlightRoute: "ClientFlightRoute",
  ClientInvoiceRoute: "ClientInvoiceRoute",
  ClientDocumentsRoute: "ClientDocumentsRoute",
  ClientByteaRoute: "ClientByteaRoute",
  ClientQuotationsRoute: "ClientQuotationsRoute",
  ClientContractsRoute: "ClientContractsRoute",
  ClientTags: "ClientTags",
  ClientActivitiesRoute: "ClientActivitiesRoute",
  ClientImportButton: "СlientImportButton",
  PreviewOfferEditTab: "PreviewOfferEditTab",
  SendOfferByEmail: "SendOfferByEmail",
  DynamicFlight: "DynamicFlight",
  WhatsappBot: "WhatsappBot",
  SendFlightBriefByEmail: "SendFlightBriefByEmail",
} as const

export type FeatureName = keyof typeof Feature

const initializeFeaturesConfig = (isDevMode: boolean): Record<FeatureName, boolean> => {
  const featureFlags: Record<FeatureName, boolean> = {} as Record<FeatureName, boolean>

  for (const feature in Feature) {
    if (Object.prototype.hasOwnProperty.call(Feature, feature)) {
      featureFlags[feature as FeatureName] = isDevMode
    }
  }

  return featureFlags
}

export const FeaturesConfig = initializeFeaturesConfig(
  Config.isDevMode || Config.isTestEnvironment,
)
