import { FeatureGate } from "#feature-gate/feature-gate.js"
// import { graphql } from '#gql'
// import { client } from '#graphql-client'
import {
  Avinode,
  Gmail,
  Hubspot,
  Outlook,
  Salesforce,
  Whatsapp,
} from "#pages/integration/components/index.js"

// import { useQuery } from '@tanstack/react-query'
//
//
// const GetUserEmailsDocument = graphql(/* GraphQL */ `
//   query GetUserEmails {
//     getUserEmails
//   }
// `)

export const Integration = () => {
  // const { data } = useQuery({
  //   queryKey: ["Emails"],
  //   queryFn: () => client.request(GetUserEmailsDocument),
  // })
  // console.log(data)
  return (
    <div className="flex w-full justify-start gap-4 p-4">
      <Avinode />
      <FeatureGate name="Integrations">
        <Hubspot />
      </FeatureGate>
      <FeatureGate name="Integrations">
        <Salesforce />
      </FeatureGate>
      <FeatureGate name="Integrations">
        <Gmail />
      </FeatureGate>
      <FeatureGate name="Integrations">
        <Outlook />
      </FeatureGate>
      <Whatsapp />
    </div>
  )
}
