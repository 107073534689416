import { Link, useNavigate, useRouterState } from "@tanstack/react-router"
import ChevronsLeftIcon from "lucide-static/icons/chevrons-left.svg"
import ArrowLeft from "lucide-static/icons/move-left.svg"
import InlineSVG from "react-inlinesvg"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  cn,
  Command,
  CommandGroup,
  CommandItemCustom,
  CommandList,
} from "@fourel/ui"

import { FeatureGate } from "#feature-gate/feature-gate.js"
import { FeaturesConfig } from "#feature-gate/features.js"
import { useMenuStore } from "#pages/lobby/menu-store.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

export const menuItems = [
  FeaturesConfig.ClientFlightRoute && {
    label: "Flights",
    url: "$client/flights",
  },
  FeaturesConfig.ClientQuotationsRoute && {
    label: "Quotations",
    url: "$client/quotations",
  },
  {
    label: "Offers",
    url: "$client/offers",
  },
  FeaturesConfig.ClientInvoiceRoute && {
    label: "Invoices",
    url: "$client/invoices",
  },
  FeaturesConfig.ClientContractsRoute && {
    label: "Contracts",
    url: "$client/contracts",
  },
].filter(Boolean)

export const informationItems = [
  {
    label: "General",
    url: "$client/general",
  },
  {
    label: "Contacts",
    url: "$client/contacts",
  },
  FeaturesConfig.ClientDocumentsRoute && {
    label: "Documents",
    url: "$client/documents",
  },
  FeaturesConfig.ClientByteaRoute && {
    label: "Documents Bytea",
    url: "$client/bytea",
  },
].filter(Boolean)

export const manageItems = [
  {
    label: "Lists",
    url: "$client/lists",
  },
  {
    label: "Triggers",
    url: "$client/triggers",
  },
  {
    label: "Activities",
    url: "$client/activities",
  },
  {
    label: "Tasks",
    url: "$client/tasks",
  },
]

export const offersItems = [
  {
    label: "Pending",
    url: "$client/offers/pending",
  },
  {
    label: "Expired",
    url: "$client/offers/expired",
  },
  {
    label: "Approved",
    url: "$client/offers/approved",
  },
  {
    label: "Signed",
    url: "$client/offers/signed",
  },
  FeaturesConfig.CancelledOffersRoute && {
    label: "Cancelled",
    url: "$client/offers/cancelled",
  },
].filter(Boolean)

export const ClientsSubmenu = () => {
  const { toggleSubmenu, isSubmenuOpen } = useMenuStore()
  const { currentOrg } = useOnboardedUserInfo()
  const navigate = useNavigate()
  const clientName = useRouterState({
    select: (s) => s.location.pathname.split("clients/")[1]?.split("/")[0],
  })

  const submenuCategoryName = useRouterState({
    select: (s) => {
      const pathnameParts = s.location.pathname.split("/")
      return pathnameParts.includes("contacts")
        ? "contacts"
        : pathnameParts[pathnameParts.length - 1]
    },
  })

  const navigateToClientsHandler = () => {
    toggleSubmenu()
    void navigate({
      to: "/$slug/clients",
      params: { slug: currentOrg.slug },
    })
  }

  const commandItemStyle =
    "mb-2 cursor-pointer gap-2 px-6 py-2 transition-all duration-200 ease-in-out" +
    " hover:bg-violet-800/10 hover:text-violet-600 dark:hover:bg-slate-800 dark:hover:text-white"

  return (
    <div
      className={cn(
        "relative hidden h-screen min-w-0 max-w-0 flex-col justify-start border-r border-gray-200 bg-gray-50 pt-4 transition-all duration-300 ease-in-out md:flex dark:bg-slate-950",
        isSubmenuOpen && "min-w-56",
      )}
    >
      <div className={cn("flex items-center gap-8", !isSubmenuOpen && "m-0 gap-0")}>
        <Button
          variant="link"
          size="sm"
          data-testid="clients-submenu-button"
          className={cn(
            "h-8 p-2",
            !isSubmenuOpen &&
              "absolute left-0 top-4 z-50 -translate-x-1/2 bg-white p-2 transition-all duration-500 ease-in-out dark:bg-slate-600",
          )}
          onClick={toggleSubmenu}
        >
          <InlineSVG
            src={ChevronsLeftIcon}
            className={cn(
              "h-5 w-5 transition-all duration-300 ease-in-out hover:text-purple-800",
              !isSubmenuOpen && "rotate-180",
            )}
          />
        </Button>
        <span
          className={cn(
            "whitespace-nowrap text-xl font-bold tracking-wider",
            !isSubmenuOpen && "hidden text-[0px]",
          )}
        >
          Clients
        </span>
      </div>
      <Button
        variant="link"
        onClick={navigateToClientsHandler}
        className={cn(
          "flex items-center justify-start gap-9 px-3 py-6 text-sm text-gray-500 transition-all duration-300 ease-in-out hover:text-purple-800 hover:no-underline",
          !isSubmenuOpen && "hidden",
        )}
      >
        <InlineSVG src={ArrowLeft} className="h-5 w-5" />
        <span>Back to clients</span>
      </Button>
      <div className="flex flex-auto overflow-hidden p-1 text-sm">
        <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
          <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
            <CommandGroup className={cn("p-0", !isSubmenuOpen && "mr-4")}>
              {menuItems.map((item) => {
                return item.label === "Offers" ? (
                  <Accordion type="single" collapsible key={item.label}>
                    <AccordionItem value={item.label}>
                      <AccordionTrigger
                        className={cn(
                          "mb-2 cursor-pointer gap-2 rounded-sm px-6 py-2 font-normal transition-all duration-300 ease-in-out hover:bg-violet-600/10 hover:text-violet-600 hover:no-underline dark:hover:bg-slate-800",
                          !isSubmenuOpen && "hidden",
                        )}
                      >
                        {item.label}
                      </AccordionTrigger>
                      <AccordionContent>
                        {offersItems.map((offer) => (
                          <Link
                            to={offer.url}
                            key={offer.label}
                            from="/$slug/clients"
                            params={{
                              slug: currentOrg.slug,
                              client: clientName,
                            }}
                          >
                            <CommandItemCustom
                              className={cn(
                                "ml-6",
                                commandItemStyle,
                                !isSubmenuOpen && "hidden",
                                offer.url.includes(submenuCategoryName)
                                  ? "bg-violet-800/10 text-violet-800 hover:bg-violet-600/90" +
                                      " hover:text-white dark:hover:bg-violet-600/90"
                                  : "",
                              )}
                            >
                              {offer.label}
                            </CommandItemCustom>
                          </Link>
                        ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                ) : (
                  <Link
                    to={item.url}
                    key={item.label}
                    from="/$slug/clients"
                    params={{
                      slug: currentOrg.slug,
                      client: clientName,
                    }}
                  >
                    <CommandItemCustom
                      key={item.label}
                      className={cn(
                        commandItemStyle,
                        !isSubmenuOpen && "hidden",
                        item.url.includes(submenuCategoryName)
                          ? "bg-violet-800/10 text-violet-800 hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                          : "",
                      )}
                    >
                      <span
                        className={cn(
                          "whitespace-nowrap",
                          !isSubmenuOpen && "hidden text-[0px]",
                        )}
                      >
                        {item.label}
                      </span>
                    </CommandItemCustom>
                  </Link>
                )
              })}
            </CommandGroup>
            <p
              className={cn(
                "my-5 flex items-center gap-2 pl-6 text-xs text-slate-400 after:block after:h-[1px] after:flex-auto after:bg-slate-400",
                !isSubmenuOpen && "hidden",
              )}
            >
              Information
            </p>
            <CommandGroup className={cn("p-0", !isSubmenuOpen && "mr-4")}>
              {informationItems.map((item) => (
                <Link
                  from="/$slug/clients"
                  to={item.url}
                  key={item.label}
                  params={{
                    slug: currentOrg.slug,
                    client: clientName,
                  }}
                >
                  <CommandItemCustom
                    className={cn(
                      commandItemStyle,
                      !isSubmenuOpen && "hidden",
                      item.url.includes(submenuCategoryName)
                        ? "bg-violet-800/10 text-violet-800 hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                        : "",
                    )}
                  >
                    <span
                      className={cn(
                        "whitespace-nowrap",
                        !isSubmenuOpen && "hidden text-[0px]",
                      )}
                    >
                      {item.label}
                    </span>
                  </CommandItemCustom>
                </Link>
              ))}
            </CommandGroup>
            <FeatureGate name="ClientActivitiesRoute">
              <p
                className={cn(
                  "my-5 flex items-center gap-2 pl-6 text-xs text-slate-400 after:block after:h-[1px] after:flex-auto after:bg-slate-400",
                  !isSubmenuOpen && "hidden",
                )}
              >
                Manage
              </p>
              <CommandGroup className={cn("p-0", !isSubmenuOpen && "mr-4")}>
                {manageItems.map((item) => (
                  <Link
                    to={item.url}
                    key={item.label}
                    from="/$slug/clients"
                    params={{
                      slug: currentOrg.slug,
                      client: clientName,
                    }}
                  >
                    <CommandItemCustom
                      className={cn(
                        commandItemStyle,
                        !isSubmenuOpen && "hidden",
                        item.url.includes(submenuCategoryName)
                          ? "bg-violet-800/10 text-violet-800 hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                          : "",
                      )}
                    >
                      <span
                        className={cn(
                          "whitespace-nowrap",
                          !isSubmenuOpen && "hidden text-[0px]",
                        )}
                      >
                        {item.label}
                      </span>
                    </CommandItemCustom>
                  </Link>
                ))}
              </CommandGroup>
            </FeatureGate>
          </CommandList>
        </Command>
      </div>
    </div>
  )
}
