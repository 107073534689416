import { useQuery } from "@tanstack/react-query"
import ChevronsUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import XIcon from "lucide-static/icons/x.svg"
import { useEffect, useMemo, useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  Popover,
  PopoverContentCombobox,
  PopoverTrigger,
} from "@fourel/ui"

import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import type { OperatorType } from "#pages/quotations/create-quote/models/trip-types.js"

import { useAircraftStore } from "../create-quote-store.js"
import type { QuoteFormType } from "../create-quote.js"

const OperatorsDocument = graphql(/* GraphQL */ `
  query Operators {
    operators {
      id
      name
    }
  }
`)

interface OperatorSelectProps {
  field: ControllerRenderProps<QuoteFormType, `legs.${number}.operatorName`>
  legIndex: number
  operatorNameField: `legs.${number}.operatorName`
  operatorIdField: `legs.${number}.operatorId`
  setValue: (
    field:
      | `legs.${number}.operatorName`
      | `legs.${number}.operatorId`
      | `legs.${number}.aircraft`,

    value: string | { id: string; model: string; registration: string },
  ) => void
}

export const OperatorSelect = ({
  field,
  legIndex,
  operatorNameField,
  operatorIdField,
  setValue,
}: OperatorSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [operatorsData, setOperatorsData] = useState<OperatorType[] | []>([])
  const { updateAircraftToLegField, updateAircraftSearchParam } = useAircraftStore()

  const { data, status } = useQuery({
    queryKey: [QueryKeys.Operators.Get],
    queryFn: () => client.request(OperatorsDocument),
    staleTime: 5 * 60 * 1000,
  })

  const fetchedOperators: OperatorType[] | [] = useMemo(() => {
    return (
      data?.operators.map((it) => ({
        id: it.id,
        name: it.name,
      })) || []
    )
  }, [data?.operators])

  useEffect(() => {
    if (fetchedOperators.length > 0) {
      setOperatorsData(fetchedOperators)
    }
  }, [fetchedOperators])

  const operatorSelectHandler = (selectedOperator: OperatorType) => {
    setValue(operatorNameField, selectedOperator.name)
    setValue(operatorIdField, selectedOperator.id)
    setValue(`legs.${legIndex}.aircraft`, {
      id: "",
      model: "",
      registration: "",
    })
    setIsOpen(false)
  }

  const removeOperatorHandler = () => {
    setValue(`legs.${legIndex}.operatorName`, "")
    setValue(`legs.${legIndex}.operatorId`, "")
    setValue(`legs.${legIndex}.aircraft`, {
      id: "",
      model: "",
      registration: "",
    })
    updateAircraftSearchParam("")
    updateAircraftToLegField({
      storeLegIndex: legIndex,
      aircrafts: [],
    })
    setOperatorsData(fetchedOperators)
  }

  const searchHandler = (value: string) => {
    if (fetchedOperators.length > 0) {
      const filteredOperator = fetchedOperators.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      )
      setOperatorsData(filteredOperator)
    }
  }

  return (
    <FormItem className="flex w-full">
      <Popover open={isOpen} onOpenChange={setIsOpen} modal>
        <div className="flex w-full items-center justify-between gap-2">
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                type="button"
                variant="outline"
                role="combobox"
                onClick={() => setOperatorsData(fetchedOperators)}
                className={cn(
                  "w-full cursor-pointer justify-between text-xs",
                  !field.value && "text-muted-foreground",
                )}
              >
                {field.value ? (
                  <div className="flex w-full justify-between">
                    <p>{field.value}</p>
                  </div>
                ) : (
                  <p>Operator (optional)</p>
                )}
                <InlineSVG
                  src={ChevronsUpDownIcon}
                  className="ml-2 size-4 shrink-0 opacity-50"
                />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <Button
            variant="outline"
            className="text-muted-foreground"
            onClick={removeOperatorHandler}
            type="button"
          >
            <InlineSVG src={XIcon} className="w-4" />
          </Button>
        </div>
        <PopoverContentCombobox className="p-0">
          <Command className="max-h-60" shouldFilter={false}>
            <CommandInput
              placeholder="Search Operator"
              className="placeholder:text-xs"
              onValueChange={(value) => searchHandler(value)}
            />
            <CommandEmpty>No operators found.</CommandEmpty>
            <CommandList>
              {status === "pending" && (
                <CommandGroup>
                  <CommandItem className="text-muted-foreground text-xs font-medium">
                    Loading...
                  </CommandItem>
                </CommandGroup>
              )}
              {status === "error" && (
                <CommandGroup>
                  <CommandItem className="text-muted-foreground text-xs font-medium">
                    Error loading operators
                  </CommandItem>
                </CommandGroup>
              )}
              {status === "success" && (
                <CommandGroup>
                  {operatorsData.length > 0 &&
                    operatorsData.map((item) => (
                      <CommandItem
                        value={item.name}
                        key={item.id}
                        onSelect={() => operatorSelectHandler(item)}
                        className="cursor-pointer text-xs"
                      >
                        {item.name}
                      </CommandItem>
                    ))}
                </CommandGroup>
              )}
            </CommandList>
          </Command>
        </PopoverContentCombobox>
      </Popover>
    </FormItem>
  )
}
