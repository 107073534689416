import { useQuery } from "@tanstack/react-query"
import ChevronsUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import { useEffect, useMemo, useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  FormMessage,
  Popover,
  PopoverContentCombobox,
  PopoverTrigger,
} from "@fourel/ui"

import { CreateAircraftModal } from "#components/create-aircraft/create-aircraft-modal.js"
import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import type { FetchedAircraftType } from "#pages/quotations/create-quote/models/trip-types.js"

import { useAircraftStore } from "../create-quote-store.js"
import type { QuoteFormType } from "../create-quote.js"

const AircraftsByOperatorIdDocument = graphql(/* GraphQL */ `
  query AircraftsByOperatorId($input: QueryAircraftsByOperatorIdInput!) {
    aircraftsByOperatorId(input: $input) {
      id
      registrationNumber
      model
      class
      operatorId
      operator {
        name
      }
    }
  }
`)

const AircraftsByModelAndRegNumberDocument = graphql(/* GraphQL */ `
  query AircraftsByModelAndRegNumber($input: QueryAircraftsByModelAndRegNumberInput!) {
    aircraftsByModelAndRegNumber(input: $input) {
      id
      registrationNumber
      model
      class
      operatorId
      operator {
        name
      }
    }
  }
`)

interface AircraftRegistrationSelectProps {
  field: ControllerRenderProps<QuoteFormType, `legs.${number}.aircraft`>
  legIndex: number
  operatorNameField: `legs.${number}.operatorName`
  operatorIdField: `legs.${number}.operatorId`
  setValue: (
    field:
      | `legs.${number}.aircraft.registration`
      | `legs.${number}.aircraft.id`
      | `legs.${number}.aircraft.model`
      | `legs.${number}.operatorName`
      | `legs.${number}.operatorId`,
    value: string,
  ) => void
  getValues: (
    field: `legs.${number}.operatorName` | `legs.${number}.operatorId`,
  ) => string
}

export const AircraftRegistrationSelect = ({
  field,
  legIndex,
  operatorNameField,
  operatorIdField,
  setValue,
  getValues,
}: AircraftRegistrationSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    aircraftToLegData,
    aircraftSearchParam,
    updateAircraftSearchParam,
    updateAircraftToLegField,
  } = useAircraftStore()
  const operatorId = getValues(operatorIdField)

  const { data: allAircraftsData } = useQuery({
    queryKey: [QueryKeys.Aircraft.GetByModelAndRegNumber, { aircraftSearchParam }],
    queryFn: () =>
      client.request(AircraftsByModelAndRegNumberDocument, {
        input: { searchParam: aircraftSearchParam, limit: 25 },
      }),
    enabled: !!aircraftSearchParam && !operatorId,
  })

  const queryByModelAndRegNumData = useMemo(() => {
    return (allAircraftsData?.aircraftsByModelAndRegNumber as FetchedAircraftType[]) ?? []
  }, [allAircraftsData])

  const { data: operatorAircraftsData } = useQuery({
    queryKey: [QueryKeys.Aircraft.GetByOperatorId, { operatorId }],
    queryFn: () =>
      client.request(AircraftsByOperatorIdDocument, {
        input: { operatorId },
      }),
    enabled: !!operatorId,
  })

  const queryByOperatorIdData = useMemo(() => {
    return (operatorAircraftsData?.aircraftsByOperatorId as FetchedAircraftType[]) ?? []
  }, [operatorAircraftsData])

  useEffect(() => {
    if (queryByOperatorIdData.length > 0) {
      updateAircraftToLegField({
        storeLegIndex: legIndex,
        aircrafts: queryByOperatorIdData,
      })
    }

    if (queryByOperatorIdData.length === 0) {
      updateAircraftToLegField({
        storeLegIndex: legIndex,
        aircrafts: queryByModelAndRegNumData,
      })
    }
  }, [
    queryByOperatorIdData,
    queryByModelAndRegNumData,
    legIndex,
    updateAircraftToLegField,
    operatorId,
  ])

  const searchHandler = (value: string) => {
    updateAircraftSearchParam(value)
    if (queryByOperatorIdData.length > 0) {
      const filteredAircrafts = queryByOperatorIdData.filter(
        (item) =>
          item.registrationNumber.toLowerCase().includes(value.toLowerCase()) ||
          item.model.toLowerCase().includes(value.toLowerCase()),
      )
      updateAircraftToLegField({
        storeLegIndex: legIndex,
        aircrafts: filteredAircrafts,
      })
    }
  }

  const aircraftSelectHandler = (selectedQueryData: FetchedAircraftType) => {
    setValue(operatorNameField, selectedQueryData.operator.name)
    setValue(operatorIdField, selectedQueryData.operatorId)
    field.onChange({
      id: selectedQueryData.id,
      registration: selectedQueryData.registrationNumber,
      model: selectedQueryData.model,
    })
    setIsOpen(false)
  }

  const aircraftForLeg = aircraftToLegData.find((item) => item.storeLegIndex === legIndex)

  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <Popover open={isOpen} onOpenChange={setIsOpen} modal>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                type="button"
                variant="outline"
                role="combobox"
                className={cn(
                  "w-full cursor-pointer justify-between text-xs",
                  !field.value && "text-muted-foreground",
                )}
              >
                {field.value.id ? (
                  <div className="flex w-full justify-between">
                    <p>{`${field.value.model} (${field.value.registration})`}</p>
                  </div>
                ) : (
                  <p className="text-muted-foreground text-xs">Aircraft registration</p>
                )}
                <InlineSVG
                  src={ChevronsUpDownIcon}
                  className="ml-2 size-4 shrink-0 opacity-50"
                />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContentCombobox className="p-0">
            <Command className="max-h-60" shouldFilter={false}>
              <CommandInput
                placeholder="Search Aircraft"
                className="placeholder:text-xs"
                onValueChange={(value) => searchHandler(value)}
              />
              <CommandEmpty>No aircraft found.</CommandEmpty>
              <CommandList>
                {aircraftForLeg &&
                  aircraftForLeg?.aircrafts.length > 0 &&
                  aircraftForLeg.aircrafts.map((item) => (
                    <CommandItem
                      value={item.id}
                      key={item.id}
                      onSelect={() => aircraftSelectHandler(item)}
                      className="cursor-pointer text-xs"
                    >
                      {`${item.model} (${item.registrationNumber})`}
                    </CommandItem>
                  ))}
              </CommandList>
            </Command>
            <CreateAircraftModal />
          </PopoverContentCombobox>
        </Popover>
      </div>
      <FormMessage className="text-xs" />
    </FormItem>
  )
}
