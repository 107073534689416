import { useMutation, useQuery } from "@tanstack/react-query"

import { Button, toast, ToastAction } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { WhatsappModal } from "#pages/integration/components/whatsapp-modal.js"
import WhatsAppLogo from "#pages/integration/icons/whatsapp.png"
import { queryClient } from "#query-client"

const CurrentUserDocument = graphql(/* GraphQL */ `
  query CurrentUser {
    currentUser {
      whatsapp
    }
  }
`)

const RemoveWhatsappDocument = graphql(/* GraphQL */ `
  mutation whatsappRemove {
    whatsappRemove
  }
`)

export const Whatsapp = () => {
  const { data } = useQuery({
    queryKey: ["currentUserToken"],
    queryFn: () => client.request(CurrentUserDocument),
  })

  const { mutate } = useMutation({
    mutationFn: () => client.request(RemoveWhatsappDocument),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["currentUserToken"] })
      toast({
        title: "Success!",
        description: "Whatsapp phone removed.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while removing WhatsApp number",
      })
    },
  })

  return (
    <div className="flex w-1/3 flex-col items-center justify-between gap-2 border border-gray-300 p-5 sm:w-1/4">
      <img src={WhatsAppLogo} alt="WhatsAppLogo" className="h-28 w-auto" />
      <span>WhatsApp</span>
      {data?.currentUser.whatsapp ? (
        <Button
          onClick={() => mutate()}
          className="w-full bg-red-500 p-0 hover:bg-red-600"
        >
          Remove phone
        </Button>
      ) : (
        <WhatsappModal />
      )}
    </div>
  )
}
