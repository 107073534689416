import { useMutation } from "@tanstack/react-query"
import Link from "lucide-static/icons/link.svg"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
  ToastAction,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { queryClient } from "#query-client"

import FourelQR from "./images/fourel-code.png"

const AddOtpRequestDocument = graphql(/* GraphQL */ `
  mutation whatsappAddOtpRequest {
    whatsappAddOtpRequest
  }
`)

export const WhatsAppIntegration = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: () => void
}) => {
  const { mutate } = useMutation({
    mutationFn: () => client.request(AddOtpRequestDocument),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["currentUserToken"] })
      toast({
        title: "Success!",
        description: "Code send to your email",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred, please try again",
      })
    },
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="py-5 sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle className="text-base">
            Establish connection with Fourel AI bot assistant
          </DialogTitle>
        </DialogHeader>
        <div className="flex">
          <div className="flex w-1/2 flex-col items-center justify-center gap-4 p-2">
            <span className="w-10/11 text-center">Scan QR code with your phone!</span>
            <img src={FourelQR} alt="FourelQR" className="size-32" />
          </div>
          <div className="flex w-1/2 flex-col gap-4 p-2">
            <span className="text-center">If you’re onboarding from your phone!</span>
            <a href="https://wa.me/15551679786?text=Start" className="self-center">
              <Button variant="outline" className="flex gap-2">
                <InlineSVG src={Link} className="size-5" /> Press this link
              </Button>
            </a>
          </div>
        </div>
        <DialogFooter className="flex gap-2 rounded-sm bg-violet-50 px-2 py-3">
          <div className="flex">
            <span className="text-center font-light">
              Press <span className="text-violet-800">/Start</span> in the bot menu and
              enter the 6-digit verification code
            </span>
          </div>
          <Button
            onClick={() => mutate()}
            className="text-nowrap bg-violet-700 hover:bg-violet-800"
          >
            Get OTP Code.
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
