import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate, useRouter, useRouterState } from "@tanstack/react-router"
import PlusIcon from "lucide-static/icons/plus.svg"
import XIcon from "lucide-static/icons/x.svg"
import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import z from "zod"

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
  Textarea,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { SpinningLogo } from "#components/spinning-logo.js"
import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import { MaskedInput } from "#pages/quotations/create-quote/components/masked-input.js"
import type {
  RequestedLegType,
  TripType,
} from "#pages/quotations/create-quote/models/trip-types.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import { AircraftRegistrationSelect } from "./components/aircraft-registration-select.js"
import { AirportSelect } from "./components/airport-select.js"
import { CurrencySelect } from "./components/currency-select.js"
import { DatePicker } from "./components/date-picker.js"
import { DuplicateAircraft } from "./components/duplicate-aircraft.js"
import { OperatorSelect } from "./components/operator-select.js"
import { QuoteSummaryModal } from "./components/quote-summary-modal.js"
import { TripSummary } from "./components/trip-summary.js"

const TripDocument = graphql(/* GraphQL */ `
  query Trip($input: QueryTripInput!) {
    trip(input: $input) {
      id
      clientId
      status
      requestedLegs {
        id
        aircraftClass
        departureDate
        pax
        sequenceNumber
        estimatedTimeOfDeparture
        estimatedTimeOfArrival
        arrivalAirport {
          id
          name
          iataCode
          icaoCode
        }
        departureAirport {
          id
          name
          iataCode
          icaoCode
        }
      }
      client {
        name
      }
    }
  }
`)

const QuoteCreateDocument = graphql(/* GraphQL */ `
  mutation QuoteCreate($input: MutationQuoteCreateInput!) {
    quoteCreate(input: $input) {
      id
    }
  }
`)

const airportSchema = z.object({
  id: z.string().trim().min(1, { message: "ID is required" }),
  code: z.string().trim().min(1, { message: "Airport code is required" }),
  name: z.string().trim().min(1, { message: "Airport name is required" }),
})

const aircraftSchema = z.object({
  id: z.string().trim().min(1, { message: "Aircraft ID is required" }),
  registration: z
    .string()
    .trim()
    .min(1, { message: "Aircraft registration is required" }),
  model: z.string().trim().min(1, { message: "Aircraft name is required" }),
})

const legSchema = z.object({
  departureAirport: airportSchema.refine((data) => data.id && data.code && data.name, {
    message: "Departure airport is required",
  }),
  arrivalAirport: airportSchema.refine((data) => data.id && data.code && data.name, {
    message: "Arrival airport is required",
  }),
  departureDate: z
    .union([z.date().nullable(), z.string().nullable()])
    .refine((val) => val !== null && val !== "", {
      message: "Departure date is required",
    }),
  arrivalDate: z
    .union([z.date().nullable(), z.string().nullable()])
    .refine((val) => val !== null && val !== "", {
      message: "Arrival date is required",
    }),
  estimatedTimeOfDeparture: z.string().min(1, {
    message: "Time of departure is required",
  }),
  estimatedTimeOfArrival: z.string().min(1, {
    message: "Time of arrival is required",
  }),
  aircraft: aircraftSchema.refine((data) => data.registration && data.id && data.model, {
    message: "Aircraft is required",
  }),
  operatorName: z.string().min(1).max(150),
  operatorId: z.string().min(1),
})

const quoteSchema = z.object({
  price: z.string().min(1, { message: "Price is required" }),
  currency: z.string().min(1, { message: "Currency is required" }),
  notes: z.string().optional(),
  legs: legSchema.array(),
})

export type QuoteFormType = z.infer<typeof quoteSchema>
export type LegFormType = z.infer<typeof legSchema>

const emptyLegData: LegFormType = {
  departureAirport: { id: "", name: "", code: "" },
  arrivalAirport: { id: "", name: "", code: "" },
  departureDate: "",
  arrivalDate: "",
  estimatedTimeOfDeparture: "",
  estimatedTimeOfArrival: "",
  aircraft: { registration: "", id: "", model: "" },
  operatorName: "",
  operatorId: "",
}

export const CreateQuote = () => {
  const { toast } = useToast()
  const router = useRouter()

  const urlTripId = useRouterState({
    select: (s) => s.location.pathname.split("create-quote/")[1],
  })
  const navigate = useNavigate()

  const { currentOrg } = useOnboardedUserInfo()
  const { data, status, isLoading } = useQuery({
    queryKey: [QueryKeys.Trips.Get, { urlTripId }],
    queryFn: () => client.request(TripDocument, { input: { tripId: urlTripId } }),
    enabled: !!urlTripId,
  })

  const queriedTrip = (data?.trip as unknown as TripType) ?? {}

  const [queriedTripLegs, setQueriedTripLegs] = useState<RequestedLegType[] | []>([])

  useEffect(() => {
    if (status === "success" && data?.trip?.requestedLegs?.length) {
      setQueriedTripLegs(data.trip.requestedLegs as unknown as RequestedLegType[])
    }
  }, [status, data])

  const { mutate } = useMutation({
    mutationFn: async (variables: VariablesOf<typeof QuoteCreateDocument>) =>
      client.request(QuoteCreateDocument, variables),
    onSuccess: () => {
      void navigate({ to: "/$slug/quotations", params: { slug: currentOrg.slug } })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while creating a Quote.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const form = useForm<QuoteFormType>({
    resolver: zodResolver(quoteSchema),
    defaultValues: {
      legs: [emptyLegData],
      notes: "",
      currency: "",
      price: "",
    },
    reValidateMode: "onChange",
    mode: "all",
  })
  const reset = form.reset

  useEffect(() => {
    if (queriedTripLegs && queriedTripLegs.length) {
      const legsData = queriedTrip?.requestedLegs?.map((leg) => ({
        departureAirport: {
          id: leg.departureAirport.id,
          name: leg.departureAirport.name,
          code: leg.departureAirport.icaoCode || leg.departureAirport.iataCode,
        },
        arrivalAirport: {
          id: leg.arrivalAirport.id,
          name: leg.arrivalAirport.name,
          code: leg.arrivalAirport.icaoCode || leg.arrivalAirport.iataCode,
        },
        departureDate: new Date(leg.departureDate),
        arrivalDate: "",
        estimatedTimeOfDeparture: leg.estimatedTimeOfDeparture!.slice(0, 5),
        estimatedTimeOfArrival: "",
        aircraft: {
          id: "",
          model: "",
          registration: "",
        },
        operatorName: "",
        operatorId: "",
      }))

      reset({
        legs: legsData,
        notes: "",
        currency: "",
      })
    }
  }, [queriedTrip.requestedLegs, queriedTripLegs, reset])

  const {
    fields: legFields,
    append,
    remove,
  } = useFieldArray({
    name: "legs",
    control: form.control,
  })

  const addLegFormHandler = () => {
    append(emptyLegData)
  }

  const removeLegFormHandler = (index: number) => {
    remove(index)
  }

  const onSubmitHandler = form.handleSubmit((values: QuoteFormType) => {
    const now = new Date()
    const expiresAt = new Date(now.getTime() + 48 * 60 * 60 * 1000).toISOString()

    const legsArray = values.legs.map((item, index) => {
      const depDate = new Date(item.departureDate!)
      const localDepartureDate = new Date(
        depDate.getTime() - depDate.getTimezoneOffset() * 60000,
      )
      const departureDateISO = localDepartureDate.toISOString()
      const arrDate = item.arrivalDate ? new Date(item.arrivalDate) : null
      const arrivalDateISO = arrDate
        ? new Date(arrDate.getTime() - arrDate.getTimezoneOffset() * 60000).toISOString()
        : "no date"
      return {
        aircraftId: item.aircraft.id,
        departureAirportId: item.departureAirport.id,
        arrivalAirportId: item.arrivalAirport.id,
        sequenceNumber: index,
        departureDate: departureDateISO ?? "no date",
        arrivalDate: arrivalDateISO ?? "no date",
        estimatedTimeOfArrival: item.estimatedTimeOfArrival,
        estimatedTimeOfDeparture: item.estimatedTimeOfDeparture,
        notes: values.notes,
        pax: +(
          queriedTrip.requestedLegs.find(
            (it: RequestedLegType) => +it.sequenceNumber === index,
          )?.pax || 1
        ),
      }
    })

    mutate({
      input: {
        quote: {
          price: values.price.toString(),
          currency: values.currency,
          tripId: queriedTrip.id,
          notes: values.notes,
          status: "accepted",
          expiresAt,
        },

        legs: legsArray,
      },
    })

    form.reset()
  })

  if (isLoading) {
    return (
      <div className="flex h-[calc(100vh-272px)] items-center justify-center md:h-[calc(100vh-128px)]">
        <SpinningLogo />
      </div>
    )
  }

  const validateTime = (
    fieldName:
      | `legs.${number}.estimatedTimeOfArrival`
      | `legs.${number}.estimatedTimeOfDeparture`,
    value: string,
    minHours?: number,
    minMinutes?: number,
  ) => {
    if (minHours !== undefined && minMinutes !== undefined) {
      const [inputHours, inputMinutes] = value.split(":").map(Number)
      const minTimeInMinutes = minHours * 60 + minMinutes
      const inputTimeInMinutes = inputHours * 60 + inputMinutes

      if (inputTimeInMinutes < minTimeInMinutes) {
        form.setError(fieldName, {
          type: "manual",
          message: `Time should not be earlier than ${minHours}:${minMinutes}`,
        })
      } else {
        form.clearErrors(fieldName)
      }
    }
  }

  return (
    <div className="box-content flex max-w-[1200px] flex-col gap-12 p-6">
      {status === "error" ? (
        <h3>Something went wrong</h3>
      ) : (
        <TripSummary queriedTrip={queriedTrip} />
      )}
      <Form {...form}>
        <form
          onSubmit={onSubmitHandler}
          className="flex w-full flex-col items-start justify-start gap-4"
        >
          <h3 className="text-xl font-semibold">Quote options</h3>
          <div className="flex w-full justify-between gap-4">
            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <CurrencySelect field={field} setValue={form.setValue} />
              )}
            />

            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormLabel className="text-sm">Price:</FormLabel>
                  <div className="flex items-center justify-between gap-2">
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value ?? ""}
                        className="text-xs"
                        placeholder="Price"
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^0-9]/g, "")
                          form.setValue("price", value)
                        }}
                      />
                    </FormControl>
                  </div>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          </div>
          {legFields.map((item, index) => (
            <div key={item.id} className="flex w-full flex-col gap-2">
              <p className="text-sm">Leg {index + 1}</p>
              <div className="flex w-full flex-wrap gap-2 lg:flex-nowrap">
                <FormField
                  control={form.control}
                  name={`legs.${index}.departureAirport`}
                  render={({ field }) => (
                    <AirportSelect
                      field={field}
                      airportField={`legs.${index}.departureAirport`}
                      placeholder="Departure airport"
                      setValue={form.setValue}
                    />
                  )}
                />
                <FormField
                  control={form.control}
                  name={`legs.${index}.arrivalAirport`}
                  render={({ field }) => (
                    <AirportSelect
                      field={field}
                      airportField={`legs.${index}.arrivalAirport`}
                      placeholder="Arrival airport"
                      setValue={form.setValue}
                    />
                  )}
                />
              </div>
              <div className="flex w-full flex-wrap gap-2 lg:flex-nowrap">
                <FormField
                  control={form.control}
                  name={`legs.${index}.departureDate`}
                  render={({ field }) => {
                    const previousLegArrivalDate =
                      index > 0 ? form.getValues(`legs.${index - 1}.arrivalDate`) : null

                    const previousLegDepartureDate =
                      index > 0 ? form.getValues(`legs.${index - 1}.departureDate`) : null

                    const minDate = previousLegArrivalDate
                      ? new Date(previousLegArrivalDate)
                      : previousLegDepartureDate
                        ? new Date(previousLegDepartureDate)
                        : null

                    return (
                      <DatePicker
                        field={field}
                        placeholder="Departure date"
                        fieldName={`legs.${index}.departureDate`}
                        setValue={form.setValue}
                        revalidate={form.trigger}
                        minDate={minDate}
                      />
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name={`legs.${index}.arrivalDate`}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      placeholder="Arrival date"
                      fieldName={`legs.${index}.arrivalDate`}
                      setValue={form.setValue}
                      revalidate={form.trigger}
                      minDate={form.getValues(`legs.${index}.departureDate`)}
                    />
                  )}
                />
              </div>
              <div className="flex w-full flex-wrap gap-2 lg:flex-nowrap">
                <FormField
                  control={form.control}
                  name={`legs.${index}.estimatedTimeOfDeparture`}
                  render={({ field }) => {
                    const previousLegArrivalDate =
                      index > 0
                        ? new Date(form.getValues(`legs.${index - 1}.arrivalDate`) || "")
                        : undefined
                    const currentLegDepartureDate = new Date(
                      form.getValues(`legs.${index}.departureDate`) || "",
                    )
                    const previousLegEstimatedTimeOfArrival =
                      index > 0
                        ? form.getValues(`legs.${index - 1}.estimatedTimeOfArrival`)
                        : undefined

                    let minHours: number | undefined
                    let minMinutes: number | undefined
                    if (
                      index > 0 &&
                      previousLegArrivalDate &&
                      previousLegEstimatedTimeOfArrival &&
                      currentLegDepartureDate &&
                      previousLegArrivalDate.toDateString() ===
                        currentLegDepartureDate.toDateString()
                    ) {
                      const [hours, minutes] = previousLegEstimatedTimeOfArrival
                        .split(":")
                        .map(Number)
                      minHours = hours
                      minMinutes = minutes
                    }
                    return (
                      <MaskedInput
                        {...field}
                        onBlur={() =>
                          validateTime(
                            `legs.${index}.estimatedTimeOfDeparture`,
                            field.value,
                            minHours,
                            minMinutes,
                          )
                        }
                        placeholder="Departure time"
                        fieldName={`legs.${index}.estimatedTimeOfDeparture`}
                        revalidate={form.trigger}
                      />
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name={`legs.${index}.estimatedTimeOfArrival`}
                  render={({ field }) => {
                    const currentLegDepartureDateString =
                      form.getValues(`legs.${index}.departureDate`) || ""
                    const currentLegArrivalDateString =
                      form.getValues(`legs.${index}.arrivalDate`) || ""

                    const currentLegDepartureDate = currentLegDepartureDateString
                      ? new Date(currentLegDepartureDateString)
                      : null
                    const currentLegArrivalDate = currentLegArrivalDateString
                      ? new Date(currentLegArrivalDateString)
                      : null

                    const isValidDate = (date: Date | null) =>
                      date instanceof Date && !isNaN(date.getTime())
                    let minHours: number | undefined
                    let minMinutes: number | undefined
                    if (
                      isValidDate(currentLegDepartureDate) &&
                      isValidDate(currentLegArrivalDate)
                    ) {
                      const currentLegEstimatedTimeOfDeparture = form.getValues(
                        `legs.${index}.estimatedTimeOfDeparture`,
                      )

                      if (
                        currentLegDepartureDate &&
                        currentLegArrivalDate &&
                        currentLegDepartureDate?.toISOString() ===
                          currentLegArrivalDate?.toISOString()
                      ) {
                        const [hours, minutes] = currentLegEstimatedTimeOfDeparture
                          .split(":")
                          .map(Number)
                        minHours = hours
                        minMinutes = minutes
                      }
                    }
                    return (
                      <MaskedInput
                        {...field}
                        onBlur={() =>
                          validateTime(
                            `legs.${index}.estimatedTimeOfArrival`,
                            field.value,
                            minHours,
                            minMinutes,
                          )
                        }
                        placeholder="Arrival time"
                        fieldName={`legs.${index}.estimatedTimeOfArrival`}
                        revalidate={form.trigger}
                      />
                    )
                  }}
                />
              </div>
              <div className="flex w-full flex-wrap gap-2 lg:flex-nowrap">
                <FormField
                  control={form.control}
                  name={`legs.${index}.operatorName`}
                  render={({ field }) => (
                    <OperatorSelect
                      field={field}
                      operatorIdField={`legs.${index}.operatorId`}
                      operatorNameField={`legs.${index}.operatorName`}
                      setValue={form.setValue}
                      legIndex={index}
                    />
                  )}
                />
                <FormField
                  control={form.control}
                  name={`legs.${index}.aircraft`}
                  render={({ field }) => (
                    <AircraftRegistrationSelect
                      field={field}
                      legIndex={index}
                      operatorNameField={`legs.${index}.operatorName`}
                      operatorIdField={`legs.${index}.operatorId`}
                      setValue={form.setValue}
                      getValues={form.getValues}
                    />
                  )}
                />
              </div>
              <div className="flex items-center justify-between gap-2">
                <DuplicateAircraft
                  legIndex={index}
                  getValues={form.getValues}
                  setValue={form.setValue}
                  revalidate={form.trigger}
                />
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => removeLegFormHandler(index)}
                  disabled={legFields.length === 1}
                  className="w-1/2 bg-red-400/30 p-0 text-xs"
                >
                  <span className="mr-2">Remove</span>
                  <InlineSVG src={XIcon} className="w-4" />
                </Button>
              </div>
              <Separator className="mt-4" />
            </div>
          ))}

          <div className="flex w-full justify-start">
            <Button
              variant="outline"
              type="button"
              onClick={addLegFormHandler}
              className="flex items-center"
            >
              <InlineSVG src={PlusIcon} className="w-5 pr-2" />
              <span className="pt-1 text-xs">Add Leg</span>
            </Button>
          </div>
          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <div className="flex w-full flex-col gap-1">
                    <FormLabel className="text-sm">Notes (Optional)</FormLabel>
                    <Textarea
                      {...field}
                      defaultValue={field.value}
                      className="text-xs"
                      placeholder="Type your notes for the quote."
                      onChange={(event) => field.onChange(event.target.value)}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex w-full justify-start gap-4">
            <Button variant="outline" type="button" onClick={() => router.history.back()}>
              Cancel
            </Button>
            <QuoteSummaryModal
              isSubmitting={form.formState.isSubmitting}
              onSubmitHandler={onSubmitHandler}
              clientName={`${status === "success" ? queriedTrip.client?.name : "No name"}`}
              selectedValues={form.getValues()}
            />
          </div>
        </form>
      </Form>
    </div>
  )
}
