import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import { Button, cn, toast, ToastAction } from "@fourel/ui"

import { Config } from "#config-fe"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import GoogleLogo from "#pages/integration/icons/google.png"
import { useIntegrationStore } from "#pages/integration/store.js"
import { queryClient } from "#query-client"

const CreateOrganizationGmailTokenDocument = graphql(/* GraphQL */ `
  mutation CreateOrganizationGmailToken(
    $input: MutationCreateOrganizationGmailTokenInput!
  ) {
    createOrganizationGmailToken(input: $input)
  }
`)

const IsOrganizationConnectedToGmailDocument = graphql(/* GraphQL */ `
  query IsUserConnectedToGmail {
    isOrganizationConnectedToGmail
  }
`)

const RevokeOrganizationGmailTokenDocument = graphql(/* GraphQL */ `
  mutation RevokeOrganizationGmailToken {
    revokeOrganizationGmailToken
  }
`)

export const Gmail = () => {
  const { gmail, removeIntegration } = useIntegrationStore()

  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: (variables: VariablesOf<typeof CreateOrganizationGmailTokenDocument>) =>
      client.request(CreateOrganizationGmailTokenDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["isUserConnectedToGmail"],
      })
      toast({
        title: "Success!",
        description: "Integration with Gmail has been successful.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while integrating with Gmail.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const { mutate: revokeToken, isPending: isRevokingToken } = useMutation({
    mutationFn: () => client.request(RevokeOrganizationGmailTokenDocument),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["isUserConnectedToGmail"],
      })
      toast({
        title: "Success!",
        description: "Integration with Gmail has been removed.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while removing Gmail.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const { data } = useQuery({
    queryKey: ["isUserConnectedToGmail"],
    queryFn: () => client.request(IsOrganizationConnectedToGmailDocument),
  })

  const gmailHandler = () => {
    const scope = [
      "https://mail.google.com",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ]
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${Config.VITE_GMAIL_CLIENT_ID}&redirect_uri=${Config.VITE_GMAIL_REDIRECT_URL}&response_type=code&scope=${encodeURIComponent(
      scope.join(" "),
    )}&access_type=offline`
  }

  useEffect(() => {
    if (gmail) {
      void mutate({ input: { code: gmail } })
    }
  }, [mutate, gmail])

  useEffect(() => {
    if (isSuccess) {
      removeIntegration("gmail")
    }
  }, [isSuccess, removeIntegration])

  return (
    <div className="flex w-1/4 flex-col items-center justify-between gap-2 border border-gray-300 p-5">
      <img src={GoogleLogo} alt="HubSpotLogo" className="h-28 w-auto" />
      <span>Gmail</span>
      <Button
        disabled={isRevokingToken || isPending}
        className={cn(
          "w-full bg-violet-900 p-0 hover:bg-violet-500",
          data?.isOrganizationConnectedToGmail && "bg-red-500",
        )}
        onClick={() => {
          if (data?.isOrganizationConnectedToGmail) {
            void revokeToken()
          } else {
            void gmailHandler()
          }
        }}
      >
        {data?.isOrganizationConnectedToGmail ? "Disconnect" : "Connect"}
      </Button>
    </div>
  )
}
