import { create } from "zustand"
import { persist } from "zustand/middleware"

// https://docs.pmnd.rs/zustand/integrations/persisting-store-data

type MenuStore = {
  isOpenMenu: boolean
  isSubmenuOpen: boolean
  toggleMenu: () => void
  toggleSubmenu: () => void
}
export const useMenuStore = create<MenuStore>()(
  persist(
    (set, get) => ({
      isOpenMenu: true,
      isSubmenuOpen: true,
      toggleMenu: () => set({ isOpenMenu: !get().isOpenMenu }),
      toggleSubmenu: () => set({ isSubmenuOpen: !get().isSubmenuOpen }),
    }),
    {
      name: "menu-state",
      partialize: (state) => ({
        isOpenMenu: state.isOpenMenu,
        isSubmenuOpen: state.isSubmenuOpen,
      }),
    },
  ),
)
